import { API_URL } from "../env";

let baseUrl = API_URL
const get_token = () => {
    let token = document.cookie.split(';').find(cookie => cookie.startsWith('token='));
    if (token) {
        token = token.split('=')[1];
    }
    return token && token !== 'null' ? token : null;
}
const get_auth_header = () => {
    let headers = {
        'Content-Type': 'application/json'
    }
    if (get_token()) {
        headers['Authorization'] = `Bearer ${get_token()}`;
    }
    return headers;
}
const parse_error = (response) => {
    return response.text().then(text => { throw new Error(text) })
}

export const get = async (url) => {
    const response = await fetch(baseUrl + url, {
        headers: get_auth_header()
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response.json();
}

export const post = async (url, data) => {
    const response = await fetch(baseUrl + url, {
        method: 'POST',
        headers: get_auth_header(),
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response.json();
}
export const streaming_post = async (url, data) => {
    const response = await fetch(baseUrl + url, {
        method: 'POST',
        headers: get_auth_header(),
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response;
}
export const streaming_post_form = async (url, data) => {
    const response = await fetch(baseUrl + url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${get_token()}`
        },
        body: data
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response;
}
export const post_form = async (url, data) => {
    const response = await fetch(baseUrl + url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${get_token()}`
        },
        body: data
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response.json();
}
export const put = async (url, data) => {
    const response = await fetch(baseUrl + url, {
        method: 'PUT',
        headers: get_auth_header(),
        body: JSON.stringify(data)
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response.json();
}


export const remove = async (url) => {
    const response = await fetch(baseUrl + url, {
        method: 'DELETE',
        headers: get_auth_header()
    });
    if (!response.ok) {
        return parse_error(response);
    }
    return response.json();
}


export const store_token = (token) => {
    if (token == null) {
        document.cookie = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
    document.cookie = `token=${token}; path=/; Secure; SameSite=Strict`;
}

export const useAuth = () => {
    return get_token() !== null;
}


