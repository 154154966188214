import React from "react";
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/bootstrap.min.css";

import "./assets/css/select2.min.css";
import "./assets/css/style.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import { App } from "./app.jsx";
import i18next from "i18next";


let lang = localStorage.getItem("lang");
//check current language i18next

if (i18next.language !== lang)
    i18next.changeLanguage(lang);

if (!lang) {
    //detect the browser language
    const browserLang = navigator.language || navigator.userLanguage;
    if (browserLang === "ar") {
        localStorage.setItem("lang", "ar");
    } else {
        localStorage.setItem("lang", "en");
    }

    lang = localStorage.getItem("lang");

    // change react-i18next language
}
const body = document.querySelector("body");
body.classList.add(lang);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);