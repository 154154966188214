import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  login02,
  loginicon01,
  loginicon02,
  loginicon03,
  loginlogo,
} from "../../imagepath";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { login, me, sendVerificationEmail } from "../../../service/auth";
import { store_token } from "../../../service/dataservice";
import { useChatContext } from "../../../service/context";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";

const Login = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { messageApi } = useChatContext();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmSendVisible, setConfirmSendVisible] = useState(false);
  const [sending, setSending] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    radio: false,
    errors: {},
  });
  const { setUser } = useChatContext();
  const onVerify = () => {
    let payload = {
      email: formData.email.toLowerCase(),
    };
    sendVerificationEmail(payload).then(() => {
      setConfirmSendVisible(false);
      messageApi.success({
        message: t("verification_email_sent"),
      });
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = t("email_is_required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = t("email_address_is_invalid");
    }

    if (!formData.password) {
      errors.password = t("password_is_required");
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(formData.password)
    ) {
      errors.password = t("password_requirements");
    }

    setFormData((prevState) => ({ ...prevState, errors }));

    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setSending(true);
      let payload = {
        email: formData.email.toLowerCase(),
        password: formData.password,
      };
      login(payload)
        .then((res) => {
          setSending(false);
          store_token(res.access_token);
          me().then((user) => {
            setUser(user);
            navigate("/");
          });
        })
        .catch((err) => {
          let details = JSON.parse(err.message);
          setSending(false);
          if (details?.detail == "User not verified") {
            setConfirmSendVisible(true);
          }

          setFormData((prevState) => ({
            ...prevState,
            errors: details,
          }));
        });
    }
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="#" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>{t("login")}</h2>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>
                            {t("email")} <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={(e) => handleChange(e)}
                          />
                          {formData.errors.email && (
                            <p className="error">{formData.errors.email}</p>
                          )}
                          {formData.errors.detail && (
                            <p className="forgotpass">
                              <span className="error">
                                {" "}
                                {formData.errors.detail}
                              </span>
                              {confirmSendVisible && (
                                <a
                                  className="text-purple"
                                  href="#"
                                  onClick={onVerify}
                                >
                                  {t("send_confirm")}
                                </a>
                              )}
                            </p>
                          )}
                        </div>
                        <div className="form-group">
                          <label>
                            {t("password")}{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                            type={passwordVisible ? "password" : ""}
                            className="form-control pass-input"
                            name="password"
                            value={formData.password}
                            onChange={(e) => handleChange(e)}
                          />
                          {formData.errors.password && (
                            <p className="error">{formData.errors.password}</p>
                          )}
                          <span
                            className="toggle-password"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordVisible ? (
                              <EyeOff className="react-feather-custom" />
                            ) : (
                              <Eye className="react-feather-custom" />
                            )}
                          </span>
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {t("remember_me")}
                              <input
                                type="checkbox"
                                name="radio"
                                value={formData.radio}
                                onChange={(e) => handleChange(e)}
                              />
                              <span className="checkmark" />
                            </label>
                          </div>
                          <Link to="/forgotpassword">
                            {t("forgot_password")}
                          </Link>
                        </div>
                        <div className="form-group login-btn">
                          {sending ? (
                            <Loading />
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              {t("login")}
                            </button>
                          )}
                        </div>
                      </form>
                      <div className="next-sign">
                        <p className="account-subtitle">
                          {t("need_an_account")}{" "}
                          <Link to="/signup">{t("sign_up")}</Link>
                        </p>
                        <div className="social-login d-none">
                          <Link to="#">
                            <img src={loginicon01} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon02} alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={loginicon03} alt="#" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
