import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import ChatSideContent from "./ChatSideContent";
import { chatbot, chatfooter3, chatfooter4, menuicon15 } from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  get_messages,
  get_conversations,
  send_message,
  delete_conversation,
  upload_attachment,
  send_message_stt,
  send_message_tts,
} from "../../../service/chat";
import Loading from "../../Loading";
import Sending from "../../Sending";
import { App, Button, Popconfirm, Switch, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../i18n";
import { useChatContext } from "../../../service/context";
import ChatAudioRecorder from "./ChatAudioRecorder";
import ChatAudioMessage from "./ChatAudioMessage";
import parse from "html-react-parser";
import BotAudio from "../../BotAudio";
import { VolumeDown, VolumeUp } from "@mui/icons-material";
import Typing from "../../Typing";
import Markdown from "marked-react";
import { useReducer } from "react";
//eslint-disable-next-line

// import { marked } from "marked";
// import showdown from "showdown";
const Chat = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  // const converter = new showdown.Converter();
  // const lang = localStorage.getItem("lang");
  const Howl = window.Howl;
  const { messageApi } = useChatContext();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const [conversations, setConversations] = useState([]); // eslint-disable-line no-unused-vars
  const [currentConversation, setCurrentConversation] = useState({}); // eslint-disable-line no-unused-vars
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [curr_message, setMessage] = useState(""); // eslint-disable-line no-unused-vars
  const [messageSending, setMessageSending] = useState(false); // eslint-disable-line no-unused-vars
  const [botPlaying, setBotPlaying] = useState(false); // eslint-disable-line no-unused-vars
  const [useAudio, setUseAudio] = useState(false); // eslint-disable-line no-unused-vars
  const [audioRecording, setAudioRecording] = useState(false);
  const [stopAudio, setStopAudio] = useState(false); // eslint-disable-line no-unused-vars
  const [audio_queue, setAudioQueue] = useState([]);
  const [audioPlayer, setAudioPlayer] = useState(null); // eslint-disable-line no-unused-vars
  const [lastBotMessage, setLastBotMessage] = useState(null); // eslint-disable-line no-unused-vars
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const onSearch = (search) => {
    get_conversations({ search: search, ordering: "" }).then((response) => {
      setConversations(response);
    });
  };
  function convertToHtml(text) {
    if (typeof text !== "string") return text;
    // Define regex patterns for new lines and list items
    var newLinePattern = /\n/g;
    var listItemPattern = /\d+\./g;

    // Replace new lines with <br> tag
    var htmlText = text.replace(newLinePattern, "<br>");

    // Replace list items with <li> tags
    htmlText = htmlText.replace(listItemPattern, "<li>");
    // Add <ul> tags for unordered list
    htmlText = "<ul>" + htmlText + "</ul>";

    return htmlText;
  }
  const parseHtml = (html) => {
    let ar = isArabicText(html);
    let newHtml = html.replace(
      /<div>/g,
      `<div style="direction: ${ar ? "rtl" : "ltr"}">`
    );
    return parse(newHtml);
  };
  const isArabicText = (text) => {
    let arabicRegex = /[\u0600-\u06FF]/;
    return arabicRegex.test(text);
  };
  const scrollToBottom = (delay = 300) => {
    setTimeout(() => {
      let chatbox = document.getElementById("chatbox");
      chatbox.scrollTop = chatbox.scrollHeight + 100;
    }, delay);
  };
  const onAudioRecorded = async (blob) => {
    let curr_messages = [...messages];
    scrollToBottom(0);
    curr_messages.push({
      audio: blob,
      sender: "user",
      timestamp: new Date(),
      id: Math.floor(Math.random() * 1000000),
    });
    setMessages(curr_messages);
    let attatchment = new FormData();
    attatchment.append("file", blob);
    upload_attachment({
      form_data: attatchment,
      conversation: id,
    }).then(async (att) => {
      let response = await send_message_stt({
        conversation: id,
        attachment: att.id,
      });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;
      let bot_message = {
        content: "",
        sender: "bot",
        timestamp: new Date(),
      };
      setMessages((messages) => [...messages, bot_message]);
      setMessageSending(true);
      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          setMessageSending(false);
          get_messages(id).then((response) => {
            // get the last message content and set it as the last bot message
            setLastBotMessage(
              React.createElement(Markdown, {
                value: response[response.length - 1].content,
              })
            );
            setMessages(
              response.map((item) => {
                return {
                  ...item,
                  audio: item?.attachments
                    ? item?.attachments?.[0]?.file_url
                    : null,
                };
              })
            );
            scrollToBottom();
          });
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });
        // if decodedChunk contains 'data: ' remove it
        let splitRegex = /\r\n\r\n/g;
        let dataRegex = /data: /g;
        decodedChunk = decodedChunk
          .replace(dataRegex, "")
          .replace(splitRegex, "");
        bot_message.content += decodedChunk;
        scrollToBottom();
        setMessages((messages) => [...messages.slice(0, -1), bot_message]);
      }
    });
  };
  const onDelete = () => {
    delete_conversation(id).then(() => {
      get_conversations({ search: "", ordering: "" }).then((response) => {
        setConversations(response);
        message.success("Chat deleted successfully");
        navigate("/chat");
      });
    });
  };

  const onSend = async () => {
    setMessageSending(true);
    setLastBotMessage(null);
    setBotPlaying(useAudio);
    setStopAudio(false);
    let curr_messages = [...messages];
    scrollToBottom(0);
    if (curr_message !== "") {
      curr_messages.push({
        content: curr_message,
        sender: "user",
        timestamp: new Date(),
      });
      setMessages(curr_messages);
      let response;
      if (useAudio)
        response = await send_message_tts({
          content: curr_message,
          conversation: id,
        });
      else
        response = await send_message({
          content: curr_message,
          conversation: id,
        });
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;
      let bot_message = {
        content: "",
        sender: "bot",
        timestamp: new Date(),
      };
      setMessages((messages) => [...messages, bot_message]);
      scrollToBottom(0);
      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          setMessageSending(false);
          get_conversations({ search: "", ordering: "" }).then((response) => {
            setConversations(response);
            let current_conversation = response.find(
              (c) => c.id === response[response.length - 1].id
            );
            setCurrentConversation(current_conversation);
            if (id === undefined)
              navigate("/chat/" + response[response.length - 1].id);
            else {
              // refresh conversations and messages
              get_messages(id).then((response) => {
                let newMessages = response;
                let lastMessage = parseHtml(
                  newMessages[newMessages.length - 1].content
                );
                setLastBotMessage(lastMessage);
                forceUpdate();
                scrollToBottom();
                setMessages(
                  newMessages.map((item) => {
                    return {
                      ...item,
                      audio: item?.attachments
                        ? item?.attachments?.[0]?.file_url
                        : null,
                    };
                  })
                );
                scrollToBottom();
              });
            }
          });
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });
        // if decodedChunk contains \r\n\r\n remove it
        let splitRegex = /\r\n\r\n/g;
        let dataRegex = /data: /g;
        let pingRegex = /: ping - \d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{6}/g;
        decodedChunk = decodedChunk
          .replace(dataRegex, "")
          .replace(splitRegex, "")
          .replace(pingRegex, "");
        let chunks = decodedChunk.split("|");
        chunks.forEach((chunk) => {
          if (chunk.includes("http:") || chunk.includes("https:")) {
            setAudioQueue((audio_queue) => [...audio_queue, chunk]);
          } else {
            let words = chunk.split(" ");
            for (let i = 0; i < words.length; i++) {
              setTimeout(() => {
                bot_message.content += words[i] + " ";
                setMessages((messages) => [
                  ...messages.slice(0, -1),
                  bot_message,
                ]);
                scrollToBottom(0);
                forceUpdate();
              }, i * 100);
            }
          }
        });
      }

      setMessage("");
    } else {
      setMessageSending(false);
      messageApi.error({
        message: t("message_cannot_be_empty"),
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    get_conversations({ search: "", ordering: "" }).then((response) => {
      setConversations(response);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (audio_queue.length > 0) {
      if (!audioPlayer && !stopAudio) {
        setBotPlaying(true);
        scrollToBottom();
        let audio = new Howl({
          src: [audio_queue[0]],
          autoplay: true,
          onend: function () {
            setAudioQueue((audio_queue) => audio_queue.slice(1));
            setAudioPlayer(null);
            setMessageSending(false);
            setBotPlaying(false);
          },
        });
        setAudioPlayer(audio);
      }
    }
  }, [audio_queue]);

  useEffect(() => {
    if (stopAudio) {
      audioPlayer.stop();
      setBotPlaying(false);
      setMessageSending(true);
    }
  }, [stopAudio]);

  useEffect(() => {
    let defaultConversation = {
      id: id,
      topic: t("new_chat"),
      description: t("start_by_typing"),
    };
    if (id) {
      setLoading(true);
      get_messages(id).then((response) => {
        setMessages(
          response.map((item) => {
            return {
              ...item,
              audio: item?.attachments
                ? item?.attachments?.[0]?.file_url
                : null,
            };
          })
        );
        let conversation = conversations.find((c) => c.id === id);
        setCurrentConversation(
          conversation ? conversation : defaultConversation
        );
        scrollToBottom();
        setLoading(false);
      });
    } else {
      setMessages([]);
      setCurrentConversation(defaultConversation);
    }
  }, [id]);

  return (
    <>
      <Header />
      <Sidebar activeClassName="chat" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/chat">{t("app")} </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{t("chat")}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="row">
              <ChatSideContent
                conversations={conversations}
                id={id}
                onSearch={onSearch}
              />
              <div className="col-xl-9">
                <div className="card chat-box box-shadow">
                  <div className=" chat-search-group ">
                    <div className="chat-user-group mb-0 d-flex align-items-center">
                      <div className="img-users call-user">
                        <Link to={id !== undefined ? "/chat/" + id : "#"}>
                          <img src={menuicon15} alt="img" />
                        </Link>
                      </div>
                      <div className="chat-users">
                        <div className="user-titles">
                          <h5>{t("chat")}</h5>
                        </div>
                        <div className="user-text">
                          <p>{formatDate(new Date(), "DD MMM YYYY")}</p>
                        </div>
                      </div>
                    </div>
                    {currentConversation && currentConversation.id && (
                      <div
                        className="chat-search-list"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Tooltip
                          title={
                            useAudio ? t("turn_off_audio") : t("turn_on_audio")
                          }
                        >
                          <Switch
                            checkedChildren={
                              <VolumeUp
                                style={{ fontSize: 15, color: "#fff" }}
                              />
                            }
                            unCheckedChildren={
                              <VolumeDown
                                style={{ fontSize: 15, color: "#fff" }}
                              />
                            }
                            checked={useAudio}
                            onChange={(checked) => {
                              setUseAudio(checked);
                            }}
                          />
                        </Tooltip>

                        <Popconfirm
                          className="dropdown-item"
                          title={t("are_you_sure_you_want_to_delete_this_chat")}
                          placement="leftTop"
                          description={t(
                            "are_you_sure_you_want_to_delete_this_chat"
                          )}
                          onConfirm={onDelete}
                          okText="Yes"
                          cancelText="No"
                          okButtonProps={{ className: "send-chat " }}
                        >
                          <Button danger>{t("delete")}</Button>
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                </div>
                {/* Chat */}
                <div className="card chat-message-box">
                  <div className="card-body p-0">
                    <div className="chat-body" id="chatbox">
                      {loading ? (
                        <Loading />
                      ) : (
                        <ul className="list-unstyled chat-message">
                          {messages?.map((message, index) => {
                            return message?.audio ? (
                              <ChatAudioMessage key={index} message={message} />
                            ) : (
                              <li
                                key={index}
                                className={`media d-flex ${
                                  message?.sender == "user"
                                    ? "sent"
                                    : "received"
                                }`}
                              >
                                {message?.sender == "bot" && (
                                  <div className="avatar flex-shrink-0">
                                    <img
                                      src={chatbot}
                                      alt="User Image"
                                      className="avatar-img rounded-circle"
                                    />
                                  </div>
                                )}
                                <div className="media-body flex-grow-1">
                                  <div className="msg-box">
                                    <div className="message-sub-box">
                                      <p
                                        key={message?.content}
                                        style={{
                                          padding: !message?.content
                                            ? "4px"
                                            : "6px",
                                          direction: isArabicText(
                                            message?.content
                                          )
                                            ? "rtl"
                                            : "ltr",
                                        }}
                                      >
                                        {lastBotMessage &&
                                        index === messages.length - 1 &&
                                        message?.sender == "bot" ? (
                                          parseHtml(convertToHtml(lastBotMessage))
                                        ) : message?.content ? (
                                          parseHtml(
                                            convertToHtml(message?.content)
                                          )
                                        ) : (
                                          <Typing />
                                        )}
                                      </p>
                                      <span>
                                        <i className="fa fa-clock-o m-r-5" />
                                        {formatDate(
                                          message?.timestamp,
                                          "DD MMM YYYY, hh:mm A"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                    {botPlaying ? (
                      <div className="chat-sending-box">
                        <BotAudio
                          onDone={() => {
                            setStopAudio(true);
                          }}
                        />
                      </div>
                    ) : messageSending ? (
                      <div className="chat-sending-box">
                        <Sending />
                      </div>
                    ) : (
                      <div className="chat-footer-box">
                        <div className="discussion-sent">
                          <div className="row gx-2">
                            {audioRecording ? (
                              <div className="col-lg-12 ">
                                <ChatAudioRecorder
                                  setAudioRecording={setAudioRecording}
                                  onAudioRecorded={onAudioRecorded}
                                />
                              </div>
                            ) : (
                              <div className="col-lg-12 ">
                                <div className="footer-discussion">
                                  <div className="inputgroups">
                                    <input
                                      type="text"
                                      placeholder={t("type_your_message_here")}
                                      onChange={(e) =>
                                        setMessage(e.target.value)
                                      }
                                      value={curr_message}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter" && !e.shiftKey) {
                                          e.preventDefault();
                                          if (curr_message !== "") {
                                            onSend();
                                          }
                                        }
                                      }}
                                    />
                                    <div className="micro-text position-icon">
                                      <img
                                        src={chatfooter4}
                                        alt="#"
                                        onClick={() => {
                                          setAudioRecording(true);
                                        }}
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        onSend();
                                      }}
                                      className="send-chat position-icon comman-flex send-btn"
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    >
                                      <a>
                                        <img src={chatfooter3} alt="#" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* /Chat */}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Chat;
