import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import EN from "./locales/en.json";
import AR from "./locales/ar.json";
import dayjs from "dayjs";


export const formatDate = (date, format="DD/MM/YYYY") => {
    const lang = localStorage.getItem("lang");
    if (lang === "ar") {
        require('dayjs/locale/ar')
        return dayjs(date).locale("ar").format(format);
    } else {
        return dayjs(date).format(format);
    }
};
i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: EN.translation
            },
            ar: {
                translation: AR.translation
            }
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });