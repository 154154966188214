
import { post, get, put } from './dataservice';

export const login = async (payload) => {
    return post('auth/token/', payload);
}
export const me = async () => {
    return get('auth/users/me/');
}
export const register = async (payload) => {
    return post('auth/register/', payload);
}
export const updateProfile = async (payload) => {
    return put('auth/users/me/', payload);
}
export const sendVerificationEmail = async (payload) => {
    return post('auth/users/confirm-email/', payload);
}

export const confirmEmail = async (token) => {
    return get(`auth/users/${token}/confirm`);
}

export const resetPassword = async (payload) => {
    return get(`auth/users/forgot-password/?email=${payload.email}`);
}
export const resetPasswordConfirm = async (payload) => {
    const { token, password } = payload;
    return post(`auth/users/${token}/reset`, {
        password,
    });
}
export const validateToken = async (token) => {
    return get(`auth/users/${token}/verify`);
}
export const getAllUsers = async () => {
    return get('auth/users/');
}
export const approveUser = async (id) => {
    return put(`auth/users/${id}/approve`);
}
export const blockUser = async (id) => {
    return put(`auth/users/${id}/block`);
}