import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { menuicon17, morning_img_01, tick_circle,morning_img_02 } from "../../imagepath";
import { useTranslation } from "react-i18next";
import { confirmEmail } from "../../../service/auth";

const EmailConfirmed = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [emailConfirmed, setEmailConfirmed] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      confirmEmail(token)
        .then(() => {
          setEmailConfirmed("confirmed");
        })
        .catch(() => {
          setEmailConfirmed("failed");
        });
    } else {
      navigate("/login");
    }
  }, [token]);

  return (
    <>
      {emailConfirmed === "confirmed" && (
        <div className="main-wrapper error-wrapper page-center m-t-100">
          <div className="error-box">
            <img className="img-fluid" src={morning_img_01} alt="Logo" />
            <h3>
              <img className="img-fluid mb-0" src={tick_circle} alt="Logo" />
              {t("email_confirmed_successfully")}
            </h3>
            <Link to="/login" className="btn btn-primary go-home">
              {t("back_to_login")}
            </Link>
          </div>
        </div>
      )}

      {emailConfirmed === "failed" && (
        <div className="main-wrapper error-wrapper page-center m-t-100">
          <div className="error-box">
            <img className="img-fluid" src={morning_img_02} alt="Logo" />
            <h3>
              <img className="img-fluid mb-0 m-r-5" src={menuicon17} width={45} alt="Logo" />
              {t("invalid_token")}
            </h3>
            <p>{t("please_click_on_the_link")}</p>
            <Link to="/login" className="btn btn-primary go-home">
              {t("back_to_login")}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default EmailConfirmed;
