/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { blog, dashboard, logout, menuicon10, menuicon08 } from "./imagepath";
import Scrollbars from "react-custom-scrollbars-2";
import { store_token } from "../service/dataservice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Sidebar = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  const isActive = () => {
    let active = props?.activeClassName;
    let activeLi = document.getElementById(active);
    activeLi?.classList.add("active");
  };

  useEffect(() => {
    isActive();
  });

  return (
    <>
      <div className="sidebar slimscroll" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner">
            <div
              id="sidebar-menu"
              className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>
                <li className="menu-title">{t("welcome")}</li>
                <li id="dashboard" className="submenu">
                  <Link to="/" id="menu-item">
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>
                    <span> {t("dashboard")} </span>
                  </Link>
                </li>
                <li id="profile">
                  <Link to="/profile">
                    <span className="menu-side">
                      <img src={menuicon08} alt="" />
                    </span>
                    <span>{t("profile")}</span>
                  </Link>
                </li>
                <li id="chat">
                  <Link to="/chat">
                    <span className="menu-side">
                      <img src={menuicon10} alt="" />
                    </span>
                    <span>{t("chat")}</span>
                  </Link>
                </li>
                <li id="logout">
                  <Link
                    className="logout-btn"
                    style={{
                      cursor: "pointer",
                    }}
                    to="#"
                    onClick={() => {
                      store_token(null);
                      navigate("/login");
                    }}
                  >
                    <span className="menu-side">
                      <img src={logout} alt="" />
                    </span>
                    <span>{t("logout")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  );
};
export default Sidebar;
