const Typing = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        width="47"
        height="47"
        style={{
          background: "transparent",
          display: "block",
          shapeRendering: "auto",
        }}
      >
        <g>
          <circle
            cx="50"
            cy="50"
            r="0"
            fill="none"
            stroke="#0079c1"
            strokeWidth="4"
          >
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1.3888888888888888s"
              values="0;25"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="0s"
            />
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="1.3888888888888888s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="0s"
            />
          </circle>
          <circle
            cx="50"
            cy="50"
            r="0"
            fill="none"
            stroke="#49176d"
            strokeWidth="4"
          >
            <animate
              attributeName="r"
              repeatCount="indefinite"
              dur="1.3888888888888888s"
              values="0;25"
              keyTimes="0;1"
              keySplines="0 0.2 0.8 1"
              calcMode="spline"
              begin="-0.6944444444444444s"
            />
            <animate
              attributeName="opacity"
              repeatCount="indefinite"
              dur="1.3888888888888888s"
              values="1;0"
              keyTimes="0;1"
              keySplines="0.2 0 0.8 1"
              calcMode="spline"
              begin="-0.6944444444444444s"
            />
          </circle>
          <g />
        </g>
      </svg>
    </div>
  );
};

export default Typing;
