import React from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { Link } from "react-router-dom";
import { profileicon } from "../../imagepath";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { useChatContext } from "../../../service/context";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../i18n";
const Profile = () => {
  const { user } = useChatContext();
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Sidebar />
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-sm-7 col-6">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">{t("dashboard")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">{t("my_profile")}</li>
                </ul>
              </div>
              <div className="col-sm-5 col-6 text-end m-b-30">
                <Link
                  to="/edit-profile"
                  className="btn btn-primary btn-rounded"
                >
                  <i className="fa fa-plus" /> {t("edit_profile")}
                </Link>
              </div>
            </div>
            <div className="card-box profile-header">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img">
                        <Link to="#">
                          <img className="avatar" src={profileicon} alt="#" />
                        </Link>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {user?.first_name} {user?.last_name}
                            </h3>
                            <small className="text-muted">{t("user")}</small>
                            <div className="staff-id">
                              {t("signup_date")} :{" "}
                              {formatDate(
                                user?.date_of_birth,
                                "dddd, MMMM D, YYYY"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">{t("phone")}:</span>
                              <span className="text">
                                <Link to>{user?.phone_number} </Link>
                              </span>
                            </li>
                            <li>
                              <span className="title">{t("email")}:</span>
                              <span className="text">
                                <Link to>{user?.email} </Link>
                              </span>
                            </li>
                            <li>
                              <span className="title">{t("birthday")}:</span>
                              <span className="text">
                                {formatDate(user?.date_of_birth, "DD/MM/YYYY")}
                              </span>
                            </li>
                            <li>
                              <span className="title">{t("address")}:</span>
                              <span className="text">{user?.address}</span>
                            </li>
                            <li>
                              <span className="title">{t("gender")}:</span>
                              <span className="text"> {t(user?.gender)} </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-tabs">
              <ul className="nav nav-tabs nav-tabs-bottom">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    to="#about-cont"
                    data-bs-toggle="tab"
                  >
                    {t("about")}
                  </Link>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane show active" id="about-cont">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-box">
                        <h3 className="card-title">
                          {t("personal_informations")}
                        </h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="experience-user">
                                <div className="before-circle" />
                              </div>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <Link to="#/" className="name">
                                    {t("bio")}
                                  </Link>
                                  <div>{user?.bio}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-user">
                                <div className="before-circle" />
                              </div>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <Link to="#/" className="name">
                                    {t("website")}
                                  </Link>
                                  <div>{user?.website}</div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="experience-user">
                                <div className="before-circle" />
                              </div>
                              <div className="experience-content">
                                <div className="timeline-content">
                                  <Link to="#/" className="name">
                                    {t("social_media_links")}
                                  </Link>
                                  <div>{user?.social_media_links}</div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Profile;
