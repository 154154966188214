import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { imguser } from "../../imagepath";
import { DatePicker } from "antd";
import Select from "react-select";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { useChatContext } from "../../../service/context";
import dayjs from "dayjs";
import { updateProfile } from "../../../service/auth";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const { user, messageApi } = useChatContext();
  const dateFormat = "YYYY-MM-DD";
  const { t } = useTranslation();
  const genders = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const [formValues, setFormValues] = useState({});

  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormValues({
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      date_of_birth: user?.date_of_birth || "",
      gender: user?.gender || "",
      address: user?.address || "",
      email: user?.email || "",
      phone_number: user?.phone_number || "",
      website: user?.website || "",
      social_media_links: user?.social_media_links || "",
    });
  }, [user]);

  const validateForm = () => {
    let newErrors = {};

    // Validate first name
    if (!formValues.first_name) {
      newErrors.first_name = t("first_name_required");
    }

    // Validate last name
    if (!formValues.last_name) {
      newErrors.last_name = t("last_name_required");
    }

    // Validate birth date
    if (!formValues.date_of_birth) {
      newErrors.date_of_birth = t("birth_date_required");
    }

    // Validate gender
    if (!formValues.gender) {
      newErrors.gender = t("gender_required");
    }

    // Validate address
    if (!formValues.address) {
      newErrors.address = t("address_required");
    }

    // Validate email
    if (!formValues.email) {
      newErrors.email = t("email_required");
    }

    // Validate phone number
    if (!formValues.phone_number) {
      newErrors.phone_number = t("phone_number_required");
    }

    // Validate website
    if (!formValues.website) {
      newErrors.website = t("website_required");
    }

    // Validate social media links
    if (!formValues.social_media_links) {
      newErrors.social_media_links = t("social_media_links_required");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      updateProfile(formValues)
        .then(() => {
          messageApi.success({
            message: t("profile_updated"),
          });
        })
        .catch(() => {
          messageApi.error({
            message: t("error_occurred"),
            description: t("profile_not_updated"),
          });
        });
    }
  };

  const handleInputChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (date, dateString) => {
    setFormValues({
      ...formValues,
      date_of_birth: dateString,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormValues({
      ...formValues,
      gender: selectedOption.value,
    });
  };

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#">{t("dashboard")} </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right">
                      <FeatherIcon icon="chevron-right" />
                    </i>
                  </li>
                  <li className="breadcrumb-item active">{t("edit_profile")}</li>
                </ul>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="card-box">
              <h3 className="card-title">{t("basic_informations")}</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-img-wrap">
                    <img
                      className="inline-block avatar"
                      src={imguser}
                      alt="user"
                    />
                  </div>
                  <div className="profile-basic m-r-150">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">{t("first_name")}</label>
                          <input
                            type="text"
                            className="form-control floating"
                            name="first_name"
                            value={formValues.first_name}
                            onChange={handleInputChange}
                          />
                          {errors.first_name && (
                            <p className="error">{errors.first_name}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">{t("last_name")}</label>
                          <input
                            type="text"
                            className="form-control floating"
                            name="last_name"
                            value={formValues.last_name}
                            onChange={handleInputChange}
                          />
                          {errors.last_name && (
                            <p className="error">{errors.last_name}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">{t("birth_date")}</label>
                          <div className="cal-icon">
                            <DatePicker
                              className="form-control datetimepicker"
                              onChange={handleDateChange}
                              suffixIcon={null}
                              defaultValue={dayjs(formValues.date_of_birth)}
                              format={dateFormat}
                            />
                          </div>
                          {errors.date_of_birth && (
                            <p className="error">{errors.date_of_birth}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group local-forms">
                          <label className="focus-label">{t("gender")}</label>
                          <Select
                            value={genders.find(
                              (x) => x.value == formValues.gender
                            )}
                            onChange={handleSelectChange}
                            options={genders}
                          />
                          {errors.gender && (
                            <p className="error">{errors.gender}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-box">
              <h3 className="card-title">{t("contact_informations")}</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group local-forms">
                    <label className="focus-label">{t("address")}</label>
                    <input
                      type="text"
                      className="form-control floating"
                      name="address"
                      value={formValues.address}
                      onChange={handleInputChange}
                    />
                    {errors.address && (
                      <p className="error">{errors.address}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group local-forms">
                    <label className="focus-label">{t("email")}</label>
                    <input
                      type="text"
                      disabled
                      className="form-control floating"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group local-forms">
                    <label className="focus-label">{t("phone_number")}</label>
                    <input
                      type="text"
                      className="form-control floating"
                      name="phone_number"
                      value={formValues.phone_number}
                      onChange={handleInputChange}
                    />
                    {errors.phone_number && (
                      <p className="error">{errors.phone_number}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-box">
              <h3 className="card-title">{t("social_information")}</h3>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group local-forms">
                    <label className="focus-label">{t("website")}</label>
                    <input
                      type="text"
                      className="form-control floating"
                      name="website"
                      value={formValues.website}
                      onChange={handleInputChange}
                    />
                    {errors.website && (
                      <p className="error">{errors.website}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group local-forms">
                    <label className="focus-label">{t("social_media_links")}</label>
                    <textarea
                      rows={1}
                      className="form-control floating"
                      name="social_media_links"
                      value={formValues.social_media_links}
                      onChange={handleInputChange}
                    />
                    {errors.social_media_links && (
                      <p className="error">{errors.social_media_links}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center m-t-20 m-b-20">
              <button className="btn btn-primary submit-btn" type="submit">
                {t("save")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
