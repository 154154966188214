/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  logo,
  baricon,
  baricon1,
  profileicon,
  globeicon,
  enIcon,
  arIcon,
  imgicon,
} from "./imagepath";
import { useChatContext } from "../service/context";
import { me } from "../service/auth";
import { store_token } from "../service/dataservice";
import { useTranslation } from "react-i18next";
const Header = () => {
  const { t } = useTranslation();
  const handlesidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };
  const { user, setUser } = useChatContext();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  useEffect(() => {
    me().then((res) => {
      setUser(res);
    });
  }, []);

  const handlesidebarmobilemenu = () => {
    document.body.classList.toggle("slide-nav");
    document.getElementsByTagName("html")[0].classList.toggle("menu-opened");
    document
      .getElementsByClassName("sidebar-overlay")[0]
      .classList.toggle("opened");
  };
  const changeLang = (lang) => {
    localStorage.setItem("lang", lang);
    window.location.reload();
  };

  return (
    <div className="main-wrapper">
      <div className="header">
        <div className="header-left">
          <Link to="/" className="logo">
            <img src={logo} width={35} height={35} alt="" />{" "}
            <span>Najeeb Ai</span>
          </Link>
        </div>
        <a id="toggle_btn" onClick={handlesidebar}>
          <img src={baricon} alt="" />
        </a>
        <Link
          id="mobile_btn"
          className="mobile_btn float-start"
          to="#"
          onClick={handlesidebarmobilemenu}
        >
          <img src={baricon1} alt="" />
        </Link>
        {/* <div className="top-nav-search mob-view">
          <form>
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
            />
            <Link className="btn">
              <img src={searchnormal} alt="" />
            </Link>
          </form>
        </div> */}
        <ul className="nav user-menu float-end">
          {user?.is_superuser && (
            <li className="nav-item dropdown d-none d-sm-block">
              <Link to="/requests" className="nav-link">
                <img src={imgicon} alt="" width={25} />
              </Link>
            </li>
          )}
          <li className="nav-item dropdown d-none d-sm-block">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <img src={globeicon} alt="" />
            </Link>
            <div className="dropdown-menu notifications">
              <Link
                className="dropdown-item"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => changeLang("en")}
              >
                <img src={enIcon} alt="" width={30} /> English
              </Link>
              <Link
                className="dropdown-item"
                to="/profile"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => changeLang("ar")}
              >
                <img src={arIcon} alt="" width={30} /> عربي
              </Link>
            </div>
          </li>
          {user && (
            <li className="nav-item dropdown has-arrow user-profile-list">
              <Link
                to="#"
                className="dropdown-toggle nav-link user-link"
                data-bs-toggle="dropdown"
              >
                <div className="user-names">
                  <h5>{user?.first_name}</h5>
                  <span>{user?.email}</span>
                </div>
                <span className="user-img">
                  <img src={profileicon} alt="Admin" />
                </span>
              </Link>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="/profile">
                  {t("my_profile")}
                </Link>
                <a
                  className="dropdown-item"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    store_token(null);
                    navigate("/login");
                  }}
                >
                  {t("logout")}
                </a>
              </div>
            </li>
          )}
          {/* <li className="nav-item ">
            <Link to="/settings" className="hasnotifications nav-link">
              <img src={settingicon01} alt="" />{" "}
            </Link>
          </li> */}
        </ul>
        <div className="dropdown mobile-user-menu float-end">
          <Link
            to="#"
            className="dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-ellipsis-vertical" />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to="/profile">
              {t("my_profile")}
            </Link>
            {user?.is_superuser && (
              <Link className="dropdown-item" to="/requests">
                {t("requests")}
              </Link>
            )}
            <hr />
            <Link
              className="dropdown-item"
              to="#"
              style={{
                cursor: "pointer",
              }}
              onClick={() => changeLang(lang === "ar" ? "en" : "ar")}
            >
              {lang === "en" ? "عربي" : "English"}
            </Link>
            <hr />
            <Link className="dropdown-item" to="/login">
              {t("logout")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
