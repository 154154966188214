import Approuter from "./approuter";
import { ChatProvider } from "./service/context";
import { App as AntdApp, notification, ConfigProvider } from "antd";
import ar_EG from "antd/es/locale/ar_EG";
import en_US from "antd/es/locale/en_US";
import "./i18n";

const lang = localStorage.getItem("lang");

export const App = () => {
  //eslint-disable-next-line
  const [api, contextHolder] = notification.useNotification();
  return (
    <ConfigProvider locale={lang === "ar" ? ar_EG : en_US}>
      <AntdApp>
        <ChatProvider
          init={{
            messageApi: api,
          }}
        >
          {contextHolder}
          <Approuter />
        </ChatProvider>
      </AntdApp>
    </ConfigProvider>
  );
};
