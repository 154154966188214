/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { Link } from "react-router-dom";
import { morning_img_03 } from "../../imagepath";
import { useChatContext } from "../../../service/context";
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useChatContext();
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const greeting = () => {
    var myDate = new Date();
    var hrs = myDate.getHours();
    var greet;
    if (hrs < 12) greet = t("good_morning");
    else if (hrs >= 12 && hrs <= 17) greet = t("good_afternoon");
    else if (hrs >= 17 && hrs <= 24) greet = t("good_evening");

    return greet;
  }; // eslint-disable-line no-unused-vars

  useEffect(() => {
    setTimeout(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
  });
  return (
    <>
      <Header />
      <Sidebar id="menu-item" id1="menu-items" activeClassName="dashboard" />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">{t("dashboard")} </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">{t("home")}</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      {greeting()},
                      <span>{user?.first_name + " " + user?.last_name} </span>
                    </h2>
                    <p>{t("welcome_to_your_dashboard")}</p>
                    <p>
                      {t("its")}: <b>{time}</b>
                    </p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_03} alt="#" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Dashboard;