import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Login from "./components/pages/login";
import Dashboard from "./components/pages/dashboard/Dashboard";
import Signup from "./components/pages/login/Signup";
import Chat from "./components/pages/chat/Chat";
import Error from "./components/pages/login/Error";
import ServerError from "./components/pages/login/ServerError";
import Profile from "./components/pages/login/Profile";
import EditProfile from "./components/pages/login/EditProfile";
import { useAuth } from "./service/dataservice";

import SignupSuccess from "./components/pages/login/SignupSuccess";
import EmailConfirmed from "./components/pages/login/EmailConfirmed";
import ForgotPassword from "./components/pages/login/ForgotPassword";
import ResetPassword from "./components/pages/login/ResetPassword";
import RequestList from "./components/pages/requests";
import Approve from "./components/pages/login/Approve";

//eslint-disable-next-line
function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

const Approuter = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route
            path="/"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/approve" element={<Approve />} />
          <Route path="/success" element={<SignupSuccess />} />
          <Route path="/confirm/:token" element={<EmailConfirmed />} />
          <Route path="/reset/:token" element={<ResetPassword />} />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/requests"
            element={
              <RequireAuth>
                <RequestList />
              </RequireAuth>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <RequireAuth>
                <EditProfile />
              </RequireAuth>
            }
          />
          <Route path="/error" element={<Error />} />
          <Route path="/server-error" element={<ServerError />} />
          {/* Chat */}
          <Route
            path="/chat"
            element={
              <RequireAuth>
                <Chat />
              </RequireAuth>
            }
          />
          <Route
            path="/chat/:id"
            element={
              <RequireAuth>
                <Chat />
              </RequireAuth>
            }
          />
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default Approuter;
