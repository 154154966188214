import React from "react";
import {
  login02,
  // loginicon01,
  // loginicon02,
  // loginicon03,
  loginlogo,
} from "../../imagepath";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { DatePicker, Select } from "antd";
import { register } from "../../../service/auth";
import { useChatContext } from "../../../service/context";
import { useTranslation } from "react-i18next";
import Loading from "../../Loading";

const Signup = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUser } = useChatContext();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [sending, setSending] = useState(false);
  const genders = [
    { value: "male", label: t("male") },
    { value: "female", label: t("female") },
  ];
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: genders[0].value,
    phone_number: "",
    address: "",
    bio: "",
    website: "",
    social_media_links: "",
    radio: false,
    errors: {},
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const onDateChange = (date, dateString) => {
    setFormData((prevState) => ({ ...prevState, date_of_birth: dateString }));
  };
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setSending(true);
      register(formData)
        .then(() => {
          setSending(false);
          setUser({
            temp_email: formData.email,
          });
          navigate("/approve");
        })
        .catch((err) => {
          let details = JSON.parse(err.message);
          setSending(false);
          setFormData((prevState) => ({
            ...prevState,
            errors: details,
          }));
        });
    }
  };
  const validateForm = () => {
    const errors = {};

    //firstname required
    if (!formData.first_name) {
      errors.first_name = t("first_name_required");
    }

    //lastname required
    if (!formData.last_name) {
      errors.last_name = t("last_name_required");
    }

    // Check if email is correct using regex
    if (!formData.email) {
      errors.email = t("email_required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = t("email_invalid");
    }

    // Check if password is empty or doest have a digit an uppercase and a lowercase letter
    if (!formData.password) {
      errors.password = t("password_required");
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(formData.password)
    ) {
      errors.password = t("password_complexity_requirement");
    }

    //confirm password
    if (!formData.confirm_password) {
      errors.confirm_password = t("confirm_password_required");
    } else if (formData.confirm_password !== formData.password) {
      errors.confirm_password = t("password_mismatch");
    }
    //date of birth
    if (!formData.date_of_birth) {
      errors.date_of_birth = t("dob_required");
    }
    //phone number not required but must be a number using regex could contain a + sign and brackets and spaces
    if (formData.phone_number) {
      if (
        !/^\+?\d{1,3}?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          formData.phone_number
        )
      ) {
        errors.phone_number = t("phone_number_invalid");
      }
    }
    //radio required
    if (!formData.radio) {
      errors.radio = t("terms_and_privacy_policy_agreement_required");
    }
    // console.log(errors);
    setFormData((prevState) => ({ ...prevState, errors }));
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };
  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            {/* Login logo */}
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="" />
                </div>
              </div>
            </div>
            {/* /Login logo */}
            {/* Login Content */}
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>{t("getting_started")}</h2>
                      {/* Form */}
                      <div className="profile-basic">
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-6">
                              <div className="form-group">
                                <label>
                                  {t("first_name")}
                                  <span className="login-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="first_name"
                                  value={formData.first_name}
                                  onChange={handleChange}
                                />
                                {formData.errors.first_name && (
                                  <p className="error">
                                    {formData.errors.first_name}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group">
                                <label>
                                  {t("last_name")}
                                  <span className="login-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="last_name"
                                  value={formData.last_name}
                                  onChange={handleChange}
                                />
                                {formData.errors.last_name && (
                                  <p className="error">
                                    {formData.errors.last_name}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <label>
                              {t("email")}{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {formData.errors.email && (
                              <p className="error">{formData.errors.email}</p>
                            )}
                          </div>
                          <div className="form-group">
                            <label>
                              {t("password")}{" "}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              type={passwordVisible ? "password" : ""}
                              className="form-control pass-input"
                              name="password"
                              value={formData.password}
                              onChange={handleChange}
                            />
                            {formData.errors.password && (
                              <p className="error">
                                {formData.errors.password}
                              </p>
                            )}
                            <span
                              className="toggle-password"
                              onClick={togglePasswordVisibility}
                            >
                              {passwordVisible ? (
                                <EyeOff className="react-feather-custom" />
                              ) : (
                                <Eye className="react-feather-custom" />
                              )}
                            </span>
                          </div>
                          <div className="form-group">
                            <label>
                              {t("confirm_password")}
                              <span className="login-danger">*</span>
                            </label>
                            <input
                              type={passwordVisible1 ? "password" : ""}
                              className="form-control pass-input"
                              name="confirm_password"
                              value={formData.confirm_password}
                              onChange={handleChange}
                            />
                            {formData.errors.confirm_password && (
                              <p className="error">
                                {formData.errors.confirm_password}
                              </p>
                            )}
                            <span
                              className="toggle-password"
                              onClick={togglePasswordVisibility1}
                            >
                              {passwordVisible1 ? (
                                <EyeOff className="react-feather-custom" />
                              ) : (
                                <Eye className="react-feather-custom" />
                              )}
                            </span>
                            <span className="profile-views feather-eye-off confirm-password" />
                          </div>
                          <hr className="hr-small" />
                          <div className="row">
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms cal-icon">
                                <label>
                                  {t("date_of_birth")}
                                  <span className="login-danger">*</span>
                                </label>
                                <DatePicker
                                  className="form-control datetimepicker"
                                  onChange={onDateChange}
                                  placeholder={t("select_date")}
                                  format="YYYY-MM-DD"
                                  name="date_of_birth"
                                  suffixIcon={null}
                                  style={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: "#2E37A4",
                                      "&:hover": {
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "none",
                                      },
                                    }),
                                  }}
                                />
                                {formData.errors.date_of_birth && (
                                  <p className="error">
                                    {formData.errors.date_of_birth}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label className="focus-label">
                                  {t("gender")}
                                </label>
                                <Select
                                  onChange={(e) =>
                                    handleChange({
                                      target: {
                                        name: "gender",
                                        value: e,
                                      },
                                    })
                                  }
                                  name={"gender"}
                                  value={formData.gender.value}
                                  options={genders}
                                  menuPortalTarget={document.body}
                                  components={{
                                    IndicatorSeparator: () => null,
                                  }}
                                  style={{ width: "100%" }}
                                  styles={{
                                    menuPortal: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused
                                        ? "none"
                                        : "2px solid rgba(46, 55, 164, 0.1);",
                                      boxShadow: state.isFocused
                                        ? "0 0 0 1px #2e37a4"
                                        : "none",
                                      "&:hover": {
                                        borderColor: state.isFocused
                                          ? "none"
                                          : "2px solid rgba(46, 55, 164, 0.1)",
                                      },
                                      borderRadius: "10px",
                                      fontSize: "14px",
                                      minHeight: "45px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen
                                        ? "rotate(-180deg)"
                                        : "rotate(0)",
                                      transition: "250ms",
                                      width: "35px",
                                      height: "35px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms phone-icon">
                                <label>{t("phone_number")}</label>
                                <input
                                  className="form-control"
                                  type="phone"
                                  placeholder={t("enter_your_phone_number")}
                                  name="phone_number"
                                  value={formData.phone_number}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>{t("address")}</label>
                                <textarea
                                  rows={3}
                                  className="form-control"
                                  type="text"
                                  placeholder={t("enter_your_address")}
                                  name="address"
                                  value={formData.address}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>{t("website")}</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t("enter_your_website")}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>{t("social_media_links")}</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder={t(
                                    "enter_your_social_media_links_optional"
                                  )}
                                  name="social_media_links"
                                  value={formData.social_media_links}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-12 col-xl-12">
                              <div className="form-group local-forms">
                                <label>{t("how_did_you_hear_about_us")}</label>
                                <textarea
                                  rows={3}
                                  className="form-control"
                                  type="text"
                                  placeholder={t("enter_your_preferences")}
                                  name="bio"
                                  value={formData.bio}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="forgotpass">
                            <div className="remember-me">
                              <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                                {t("i_agree_to_the")}
                                <Link to="#">
                                  &nbsp; {t("terms_of_service")}{" "}
                                </Link>
                                &nbsp; {t("and")}
                                <Link to="#">
                                  &nbsp; {t("privacy_policy")}{" "}
                                </Link>
                                <input
                                  type="checkbox"
                                  name="radio"
                                  value={formData.radio}
                                  onChange={handleChange}
                                />
                                <span className="checkmark" />
                              </label>
                              {formData.errors.radio && (
                                <p className="error">{formData.errors.radio}</p>
                              )}
                            </div>
                          </div>

                          <div className="form-group login-btn">
                            {sending ? (
                              <Loading />
                            ) : (
                              <>
                                {formData.errors.detail && (
                                  <p className="forgotpass">
                                    <span className="error">
                                      {t(formData.errors.detail)}
                                    </span>
                                  </p>
                                )}
                                <button
                                  className="btn btn-primary btn-block"
                                  type="submit"
                                >
                                  {t("sign_up")}
                                </button>
                              </>
                            )}
                          </div>
                        </form>
                      </div>
                      {/* /Form */}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          {t("already_have_account")}{" "}
                          <Link to="/login">{t("login")}</Link>
                        </p>
                        {/* Social Login */}
                        {/* <div className="social-login">
                      <Link to="#">
                        <img src={loginicon01} alt="#" />
                      </Link>
                      <Link to="#">
                        <img src={loginicon02} alt="#" />
                      </Link>
                      <Link to="#">
                        <img src={loginicon03} alt="#" />
                      </Link>
                    </div> */}
                        {/* /Social Login */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Login Content */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
