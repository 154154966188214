import React, { useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import PropTypes from "prop-types";
import { chatfooter3, menuicon17 } from "../../imagepath";

const ChatAudioRecorder = ({ setAudioRecording, onAudioRecorded }) => {
  //eslint-disable-next-line
  const [wavesurfer, setWavesurfer] = React.useState(null);
  const [record, setRecord] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const createWaveSurfer = () => {
    if (wavesurfer) {
      wavesurfer.destroy();
    }
    let wave = WaveSurfer.create({
      container: "#waveform_rec",
      barGap: 1,
      height: 50,
      width: "80vh",
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 3,
      barMinHeight: 1,
      responsive: true,
      waveColor: "rgb(0, 169, 209)",
    });

    let record = wave.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform: true,
        renderRecordedAudio: false,
      })
    );
    record.on("record-end", (blob) => {
      wave.empty();
      record.stopMic();
      setAudioRecording(false);
      onAudioRecorded(blob);
    });
    record.on("record-progress", (time) => {
      updateProgress(time);
    });
    setWavesurfer(wave);
    setRecord(record);
  };
  const startRecording = () => {
    //get first available audio device
    RecordPlugin.getAvailableAudioDevices().then((devices) => {
      const deviceId = devices[0].deviceId;

      record.startRecording({ deviceId }).then(() => {});
    });
  };
  const stopRecording = () => {
    record.stopRecording();
  };
  const updateProgress = (time) => {
    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
    setProgress(formattedTime);
  };
  useEffect(() => {
    createWaveSurfer();
  }, []);
  useEffect(() => {
    record && startRecording();
  }, [record]);

  return (
    <div className="footer-discussion">
      <div className="inputgroups">
        <div className="audio-recorder" id="waveform_rec"></div>
        <div className="micro-text position-icon">
          <img
            src={menuicon17}
            width={20}
            alt="#"
            onClick={() => {
              setAudioRecording(false);
            }}
          />{" "}
          {progress}
        </div>
        <div className="send-chat position-icon comman-flex send-btn">
          <a>
            <img src={chatfooter3} alt="#" onClick={stopRecording} />
          </a>
        </div>
      </div>
    </div>
  );
};
ChatAudioRecorder.propTypes = {
  setAudioRecording: PropTypes.func.isRequired,
  onAudioRecorded: PropTypes.func.isRequired,
};
export default ChatAudioRecorder;
