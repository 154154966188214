import React, { useState } from "react";
import { Link } from "react-router-dom";
import { login02, loginlogo } from "../../imagepath";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../../../service/auth";
import { useChatContext } from "../../../service/context";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { messageApi } = useChatContext();
  const [formData, setFormData] = useState({
    email: "",
    errors: {},
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.email) {
      errors.email = t("email_is_required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = t("email_address_is_invalid");
    }
    setFormData((prevState) => ({ ...prevState, errors }));

    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let payload = {
        email: formData.email.toLowerCase(),
        password: formData.password,
      };
      resetPassword(payload)
        .then(() => {
          messageApi.success({
            message: t("reset_email_sent"),
          });
        })
        .catch((err) => {
          let details = JSON.parse(err.message);

          setFormData((prevState) => ({
            ...prevState,
            errors: details,
          }));
        });
    }
  };
  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/home">
                          <img src={loginlogo} alt="#" />
                        </Link>
                      </div>
                      <h2>{t("reset_password")}</h2>
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>
                            {t("email")} <span className="login-danger">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group login-btn">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                          >
                            <Link to="/login" />
                            {t("reset_password")}
                          </button>
                        </div>
                      </form>
                      <div className="next-sign">
                        <p className="account-subtitle">
                          {t("need_an_account")}
                          <Link to="/login"> {t("login")}</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
