import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ChatContext = createContext();

export const useChatContext = () => {
    return useContext(ChatContext);
}
export const ChatProvider = ({ children, init }) => {
    const [user, setUser] = useState({});
    const [conversations, setConversations] = useState([]);
    const [audioNotes, setAudioNotes] = useState([]);
    const { messageApi } = init;

    const state = {
        user,
        setUser,
        conversations,
        setConversations,
        messageApi,
        audioNotes,
        setAudioNotes
    }

    return (
        <ChatContext.Provider value={state}>
            {children}
        </ChatContext.Provider >
    );
};

ChatProvider.propTypes = {
    children: PropTypes.node.isRequired,
    init: PropTypes.object,
};


