/* eslint react/prop-types: 0 */

import React, { useEffect } from "react";
import { chatbot, chaticon4, chaticon5 } from "../../imagepath";
import { formatDate } from "../../../i18n";
import WaveSurfer from "wavesurfer.js";
import { useChatContext } from "../../../service/context";
import markdown from "@wcj/markdown-to-html";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
const ChatAudioMessage = ({ message }) => {
  const { audioNotes, setAudioNotes } = useChatContext();
  const [playing, setPlaying] = React.useState(false);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const markdown_to_html = (md) => {
    return markdown(md);
  };
  const createWaveSurfer = () => {
    let old = audioNotes.find((x) => x.id == message?.id);
    if (old) {
      old.wave.destroy();
      setAudioNotes((prev) => prev.filter((x) => x.id != message?.id));
    }

    let wave = WaveSurfer.create({
      container: "#waveform_" + message?.id,
      barGap: 1,
      height: 30,
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 0,
      barMinHeight: 1,
      responsive: true,
      waveColor: "rgb(0, 169, 209)",
    });
    wave.on("finish", () => {
      setPlaying(false);
    });
    if (
      typeof message?.audio === "string" ||
      message?.audio instanceof String
    ) {
      wave.load(message?.audio);
    } else {
      wave.loadBlob(message?.audio);
    }
    wave.on("ready", () => {
      setPlaying(false);
    });

    setAudioNotes((prev) => [...prev, { id: message?.id, wave: wave }]);
  };

  const playPause = (id) => {
    let note = audioNotes.find((x) => x.id == id);
    if (note) {
      note.wave.playPause();
      note.wave.isPlaying() ? setPlaying(true) : setPlaying(false);
    }
  };
  useEffect(() => {
    createWaveSurfer();
  }, [message]);
  const formatMessage = (message) => {
    if (message == "") return t("thinking");
    let msg = message.replace(/OpenAI/g, "Najeeb");
    msg = markdown_to_html(msg);
    return parse(msg);
  };
  return (
    <li
      className={`media d-flex ${
        message?.sender == "user" ? "sent" : "received"
      }`}
    >
      {message?.sender == "bot" && (
        <div className="avatar flex-shrink-0">
          <img
            src={chatbot}
            alt="User Image"
            className="avatar-img rounded-circle"
          />
        </div>
      )}
      <div className="media-body flex-grow-1">
        <div className="msg-box">
          <div className="message-sub-box">
            <div
              className="message-audio"
              style={{
                borderRadius: message?.content
                  ? "10px 10px 0px 0px"
                  : "10px 10px 10px 10px",
                direction: lang === "ar" ? "rtl" : "ltr",
                display: "flex",
                flexDirection: lang === "ar" ? "row-reverse" : "row",
              }}
            >
              <img
                className="play-chat "
                src={playing ? chaticon5 : chaticon4}
                alt="audio"
                onClick={() => playPause(message?.id)}
              />
              <div
                id={"waveform_" + message?.id}
                style={{ minWidth: "90%" }}
              ></div>
            </div>
            {message?.content && (
              <p
                style={{
                  borderRadius: "0px 0px 10px 10px",
                }}
              >
                {formatMessage(message?.content)}
              </p>
            )}
            <span>
              <i className="fa fa-clock-o m-r-5" />
              {formatDate(message?.timestamp, "DD MMM YYYY, hh:mm A")}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatAudioMessage;
