import { get, remove, streaming_post, post_form, post } from './dataservice';

export const get_conversations = async (payload) => {
    let { search, ordering } = payload;
    return get('chat/conversations/?search=' + search + '&ordering=' + ordering);
}
export const get_messages = async (id) => {
    return get(`chat/conversations/${id}/messages`);
}
export const send_message = async (payload) => {
    //eslint-disable-next-line
    let { content, conversation } = payload

    return streaming_post(`chat/messages/?conversation_id=${conversation ? conversation : ""}`, { content });
}
export const delete_conversation = async (id) => {
    return remove(`chat/conversations/${id}`);
}
export const upload_attachment = async (payload) => {
    let { form_data, conversation } = payload
    return post_form(`chat/messages/attachment?conversation_id=${conversation ? conversation : ""}`, form_data);
}
export const send_message_stt = async (payload) => {
    //eslint-disable-next-line
    let { conversation, attachment } = payload

    return streaming_post(`chat/messages/stt?conversation_id=${conversation ? conversation : ""}&attachment_id=${attachment}`, {});
}
export const send_message_tts = async (payload) => {
    //eslint-disable-next-line
    let { conversation, content } = payload

    return streaming_post(`chat/messages/tts?conversation_id=${conversation ? conversation : ""}`, { content });
}

export const format_markdown = async (payload) => {
    let { text } = payload
    return post(`chat/messages/markdown`, { text });
}
