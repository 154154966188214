import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { menuicon10, searchnormal } from "../../imagepath";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../i18n";

const ChatSideContent = ({ conversations, id, onSearch }) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    onSearch(search);
  }, [search]);

  return (
    <div className="col-xl-3 d-flex">
      <div className="card chat-box-clinic ">
        <div className="chat-widgets ">
          <div className="top-liv-search top-chat-search">
            <form>
              <div className="chat-search">
                <div className="form-group me-2 mb-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("search")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <a className="btn">
                    <img src={searchnormal} alt="#" />
                  </a>
                </div>
                <div className="add-search">
                  <Link to="/chat">
                    <i className="fa fa-plus" />
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div
            className="chat-side-body"
          >
            {conversations?.length > 0 ? (
              conversations?.map((conversation, index) => {
                return (
                  <Link
                    to={`/chat/${conversation?.id}`}
                    key={index}
                    className={`chat-user-group d-flex align-items-center ${
                      conversation?.id === id ? "active" : ""
                    }`}
                  >
                    <div className="m-r-5 call-user">
                      <a to="#">
                        <img src={menuicon10} alt="img" />
                      </a>
                    </div>
                    <div className="chat-users">
                      <div className="user-titles d-flex">
                        <h5>
                          {t("chat")} {index + 1}{" "}
                        </h5>
                        <div className="chat-user-time">
                          <p>
                            <i className="fa fa-clock-o m-r-5" />
                            {formatDate(
                              conversation?.created_at,
                              "DD MMM YYYY, hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="user-text d-flex">
                        <p>
                          {conversation?.message_count} {t("messages")}
                        </p>
                      </div>
                    </div>
                  </Link>
                );
              })
            ) : (
              <div className="d-flex p-15">
                <div className="user-text d-flex">
                  <p>{t("No_conversation_found")}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChatSideContent.propTypes = {
  conversations: PropTypes.array,
  id: PropTypes.string,
  loading: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default ChatSideContent;
