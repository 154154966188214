/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import { onShowSizeChange, itemRender } from "../../Pagination";
import {
  imagesend,
  refreshicon,
  searchnormal,
  sorticon,
  sorticon02,
  tick_circle,
} from "../../imagepath";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { approveUser, blockUser, getAllUsers } from "../../../service/auth";
import { formatDate } from "../../../i18n";
import { useChatContext } from "../../../service/context";
const RequestList = () => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const { messageApi } = useChatContext();

  const onApprove = (id) => {
    approveUser(id).then(() => {
      messageApi.success({
        message: t("email_sent"),
      });
      onRefresh();
    });
  };
  const onBlock = (id) => {
    blockUser(id).then(() => {
      onRefresh();
    });
  };
  const onRefresh = () => {
    getAllUsers().then((response) => {
      setDatasource(response);
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const [datasource, setDatasource] = useState([
    {
      id: "1",
      Name: "Andrea Lalema",
      Email: "Otolaryngology",
      JoiningDate: "01.10.2022",
      Action: "",
    },
  ]);
  const columns =
    width > 768
      ? [
          {
            title: t("name"),
            dataIndex: "first_name",
            sorter: (a, b) => a.Name.length - b.Name.length,
            render: (text, record) => (
              <>
                <p className="profile-image">
                  <Link to="#">
                    {record.first_name + " " + record.last_name}
                  </Link>
                </p>
              </>
            ),
          },
          {
            title: t("email"),
            dataIndex: "email",
            sorter: (a, b) => a.Email.length - b.Email.length,
          },
          {
            title: t("joiningdate"),
            dataIndex: "signup_date",
            sorter: (a, b) => a.JoiningDate.length - b.JoiningDate.length,
            render: (text, record) => (
              <span>{formatDate(record.signup_date)}</span>
            ),
          },
          {
            title: t("action"),
            dataIndex: "Action",
            render: (text, record) => (
              <>
                <div className="text-end">
                  {record?.is_approved ? (
                    <span className="badge bg-success me-2">{t("active")}</span>
                  ) : (
                    <button
                      className="btn btn-primary me-2"
                      onClick={() => {
                        onApprove(record.id);
                      }}
                    >
                      <img src={tick_circle} alt="" width={20} /> {t("approve")}
                    </button>
                  )}
                  {record?.is_active ? (
                    <button
                      className="btn btn-warning me-2"
                      onClick={() => onBlock(record.id)}
                    >
                      <img src={sorticon02} alt="" width={20} /> {t("block")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning me-2"
                      onClick={() => onBlock(record.id)}
                    >
                      <img src={sorticon} alt="" width={20} /> {t("unblock")}
                    </button>
                  )}
                </div>
              </>
            ),
          },
        ]
      : [
          {
            title: t("name"),
            dataIndex: "first_name",
            sorter: (a, b) => a.Name.length - b.Name.length,
            render: (text, record) => (
              <>
                <h4 className="profile-image">
                  <Link to="#">
                    {record.first_name + " " + record.last_name}
                  </Link>
                </h4>
                <p>{record.email}</p>
              </>
            ),
          },
          {
            title: t("action"),
            dataIndex: "Action",
            render: (text, record) => (
              <>
                <div className="text-end">
                  {record?.is_approved ? (
                    <span className="badge bg-success me-2 btn-block m-b-10">{t("active")}</span>
                  ) : (
                    <button
                      className="btn btn-primary me-2  btn-block m-b-10"
                      onClick={() => {
                        onApprove(record.id);
                      }}
                    >
                      <img src={tick_circle} alt="" width={20} /> {t("approve")}
                    </button>
                  )}
                  {record?.is_active ? (
                    <button
                      className="btn btn-warning me-2  btn-block m-b-10"
                      onClick={() => onBlock(record.id)}
                    >
                      <img src={sorticon02} alt="" width={20} /> {t("block")}
                    </button>
                  ) : (
                    <button
                      className="btn btn-warning me-2  btn-block m-b-10"
                      onClick={() => onBlock(record.id)}
                    >
                      <img src={sorticon} alt="" width={20} /> {t("unblock")}
                    </button>
                  )}
                </div>
              </>
            ),
          },
        ];

  useEffect(() => {
    onRefresh();

    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="patient-list"
      />
      <div className="page-wrapper">
        <div className="content">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="#"> {t("requests")} </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <i className="feather-chevron-right" />
                  </li>
                  <li className="breadcrumb-item active">
                    {t("request_list")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">
                <div className="card-body">
                  {/* Table Header */}
                  <div className="page-table-header mb-2">
                    <div className="row align-items-center">
                      <div className="col">
                        <div className="doctor-table-blk">
                          <h3> {t("request_list")} </h3>
                          <div className="doctor-search-blk">
                            <div className="top-nav-search table-search-blk">
                              <form>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("search")}
                                />
                                <Link className="btn">
                                  <img src={searchnormal} alt="#" />
                                </Link>
                              </form>
                            </div>
                            <div className="add-group">
                              <Link
                                to="#"
                                className="btn btn-primary doctor-refresh ms-2"
                                onClick={onRefresh}
                              >
                                <img src={refreshicon} alt="#" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Table Header */}
                  <div className="table-responsive doctor-list">
                    <Table
                      pagination={{
                        total: datasource.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        // showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                        locale: {
                          prevText: t("prev"),
                          nextText: t("next"),
                          showing: t("show"),
                        },
                      }}
                      columns={columns}
                      dataSource={datasource}
                      rowSelection={rowSelection}
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestList;
