import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  login02,
  loginlogo,
  menuicon17,
  morning_img_02,
} from "../../imagepath";
import { useTranslation } from "react-i18next";
import { resetPasswordConfirm, validateToken } from "../../../service/auth";
import { useChatContext } from "../../../service/context";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const { messageApi } = useChatContext();
  const [tokenValid, setTokenValid] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    password2: "",
    errors: {},
  });

  useEffect(() => {
    if (token) {
      validateToken(token)
        .then(() => {
          setTokenValid(true);
        })
        .catch(() => {
          setTokenValid(false);
        });
    }
  }, [token]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };
  const validateForm = () => {
    const errors = {};

    if (!formData.password) {
      errors.password = t("password_is_required");
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(formData.password)
    ) {
      errors.password = t("password_requirements");
    }

    if (!formData.password2) {
      errors.password2 = t("password_is_required");
    }

    if (formData.password !== formData.password2) {
      errors.password2 = t("password_mismatch");
    }

    setFormData((prevState) => ({ ...prevState, errors }));

    return Object.keys(errors).length === 0;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let payload = {
        token,
        password: formData.password,
      };
      resetPasswordConfirm(payload)
        .then(() => {
          messageApi.success({
            message: t("password_reset_success"),
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        })
        .catch((err) => {
          let details = JSON.parse(err.message);

          setFormData((prevState) => ({
            ...prevState,
            errors: details,
          }));
        });
    }
  };
  return (
    <>
      {!tokenValid ? (
        <div className="main-wrapper error-wrapper page-center m-t-100">
          <div className="error-box">
            <img className="img-fluid" src={morning_img_02} alt="Logo" />
            <h3>
              <img
                className="img-fluid mb-0 m-r-5"
                src={menuicon17}
                width={45}
                alt="Logo"
              />
              {t("invalid_token")}
            </h3>
            <p>{t("please_click_on_the_link")}</p>
            <Link to="/login" className="btn btn-primary go-home">
              {t("back_to_login")}
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <div className="main-wrapper login-body">
            <div className="container-fluid px-0">
              <div className="row">
                <div className="col-lg-6 login-wrap">
                  <div className="login-sec">
                    <div className="log-img">
                      <img className="img-fluid" src={login02} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 login-wrap-bg">
                  <div className="login-wrapper">
                    <div className="loginbox">
                      <div className="login-right">
                        <div className="login-right-wrap">
                          <div className="account-logo">
                            <Link to="/home">
                              <img src={loginlogo} alt="#" />
                            </Link>
                          </div>
                          <h2>{t("reset_password")}</h2>
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label>
                                {t("new_password")}{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="password"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                {t("confirm_password")}{" "}
                                <span className="login-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="password2"
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group login-btn">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                <Link to="/login" />
                                {t("reset_password")}
                              </button>
                            </div>
                          </form>
                          <div className="next-sign">
                            <p className="account-subtitle">
                              {t("need_an_account")}
                              <Link to="/login"> {t("login")}</Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
