import React from "react";
import { Link } from "react-router-dom";
import { morning_img_01, tick_circle } from "../../imagepath";
import { useChatContext } from "../../../service/context";
import { useTranslation } from "react-i18next";
const Approve = () => {
  const { user } = useChatContext();
  const { t } = useTranslation();
  return (
    <>
      <div className="main-wrapper error-wrapper page-center m-t-100">
        <div className="error-box">
          <img className="img-fluid" src={morning_img_01} alt="Logo" />
          <h3>
            <img className="img-fluid mb-0" src={tick_circle} alt="Logo" />
            {t("signup_successfully")}
          </h3>
          <p>
            {" "}
            {t("we_will_contact_you_at")} <b>{user.temp_email}</b>
          </p>
          <Link to="/login" className="btn btn-primary go-home">
            {t("back_to_login")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default Approve;
